import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "news-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_title, {
      level: 5,
      class: _normalizeClass(["font-bold", _ctx.poolNewsList.length > 0 ? 'mb-6' : 'mb-0'])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("News")), 1)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.poolNewsListLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (_openBlock(), _createBlock(_component_a_list, {
          key: 1,
          class: "custom-list news-list__list",
          "item-layout": "horizontal",
          "data-source": _ctx.poolNewsList
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, _createSlots({
              default: _withCtx(() => [
                _createVNode(_component_a_list_item_meta, {
                  description: 
              item?.created ? _ctx.formatDateTimePretty(item?.created) : ''
            
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.body), 1)
                  ]),
                  _: 2
                }, 1032, ["description"])
              ]),
              _: 2
            }, [
              (!_ctx.readonly)
                ? {
                    name: "actions",
                    fn: _withCtx(() => [
                      _createVNode(_component_ConfirmModal, {
                        title: _ctx.t('Delete news'),
                        body: _ctx.t('Are you sure you want to delete this news?'),
                        onOk: () => _ctx.handleDeletePoolNews(item.id),
                        type: _ctx.ConfirmModalTypes.danger
                      }, {
                        trigger: _withCtx(({ open }) => [
                          _createVNode(_component_a_button, {
                            type: "text",
                            shape: "circle",
                            class: "p-0",
                            onClick: open,
                            loading: 
                    _ctx.currentPoolNewsIdDelete === item.id && _ctx.deletePoolNewsLoading
                  
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_AppIcon, {
                                class: "news-list__list-delete cursor-pointer",
                                name: "trash"
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick", "loading"])
                        ]),
                        _: 2
                      }, 1032, ["title", "body", "onOk", "type"])
                    ])
                  }
                : undefined
            ]), 1024)
          ]),
          _: 1
        }, 8, ["data-source"]))
  ]))
}