import gql from "graphql-tag";

export const deletePoolNewsGql = gql`
  mutation DeletePoolNews($input: DeletePoolNewsInput!) {
    deletePoolNews(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
