import { computed } from "vue";
import { poolDetailsGql } from "@/api/pool/poolDetails";
import {
  PoolDetails,
  PoolDetailsVariables,
  PoolDetails_poolDetails_PoolItem,
} from "@/api/pool/__generated__/PoolDetails";
import { parsePoolItem } from "@/shared/utils/pool";
import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import { updatePoolGql } from "@/api/pool/updatePool";
import isEmpty from "lodash/isEmpty";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  UpdatePool,
  UpdatePoolVariables,
  UpdatePool_updatePool_PoolItem,
} from "@/api/pool/__generated__/UpdatePool";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { UpdatePoolInput } from "../../../__generated__/globalTypes";

export const usePoolDetails = (poolHash: string) => {
  const { data: poolData, loading: poolDataLoading } = useQuerySingleNode<
    PoolDetails_poolDetails_PoolItem,
    PoolDetails,
    PoolDetailsVariables
  >(
    poolDetailsGql,
    "PoolItem",
    () => ({ input: { poolHash } }),
    () => ({ enabled: !!poolHash })
  );

  const { mutate: updatePoolMutate, loading: updatePoolLoading } =
    useCustomMutation<UpdatePool, UpdatePoolVariables>(updatePoolGql, {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: poolDetailsGql, variables: { input: { poolHash } } },
      ],
    });

  const updatePool = async (input: UpdatePoolInput) => {
    const updatePoolResponse = await updatePoolMutate({
      input,
    });
    const parsedResponse = parseGqlResponse<UpdatePool_updatePool_PoolItem>(
      "PoolItem",
      updatePoolResponse
    );

    console.log("updatePool:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updatePoolResponse) {
      throw new Error("Failed to UpdatePool");
    }

    return parsedResponse.data;
  };

  return {
    poolData: computed(() => parsePoolItem(poolData.value)),
    poolDataLoading,
    updatePool,
    updatePoolLoading,
  };
};
