import { computed, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import attempt from "lodash/attempt";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { DocumentNode } from "graphql";
import {
  VariablesParameter,
  OptionsParameter,
} from "@vue/apollo-composable/dist/useQuery";
import { OperationVariables } from "@apollo/client";
import { fetchMoreQueryUpdater } from "@/api/graphqlClient/fetchMoreQueryUpdater";

/**
 * Note: Experimental single query node that will automatically handle response parsing
 *
 * @param document - graphql document to query
 * @param variables - graphql variables to query if any
 * @param options - graphql query options
 */
export const useQuerySingleNode = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TGqlSuccessType = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TGqlResult = any,
  TGqlVariables extends OperationVariables = OperationVariables
>(
  document: DocumentNode,
  successTypeName: string,
  variables: VariablesParameter<TGqlVariables>,
  options: OptionsParameter<TGqlResult, TGqlVariables>
) => {
  const {
    result,
    loading,
    networkStatus,
    error,
    refetch,
    fetchMore,
    onResult,
    onError,
    variables: resolvedVariables,
  } = useQuery<TGqlResult, TGqlVariables>(document, variables, options);
  const fetchMoreLoading = ref(false);

  const data = computed(() => {
    const parsedResponse = parseGqlResponse<TGqlSuccessType>(
      successTypeName,
      result.value
    );

    if (!isEmpty(parsedResponse.error?.errors) || !parsedResponse) {
      console.error("useQuerySingleNode error", successTypeName);
    }

    if (!loading.value) {
      console.log("useQuerySingleNode: parsedResponse", {
        successTypeName,
        parsedResponse,
      });
    }

    return parsedResponse.data;
  });

  /**
   * Experimental fetch more when pagination is a simple pagination input
   *
   * @param rootKey - root keys are success gql result root object
   * @returns
   */
  const fetchMoreSimplePaginationInput = async (rootKey) => {
    // try to get input from variables - if this is a simple object it will work
    let currentInput = get(variables, "input");

    // check if variable is a function then attempt to call
    if (isFunction(variables)) {
      currentInput = attempt(variables);

      // check if variable is a nested input
      if (currentInput.input) {
        currentInput = currentInput.input;
      }
    }

    // validate if current input can be handled by this method
    if (!currentInput || !currentInput.pagination) {
      console.warn(
        "fetchMoreSimplePaginationInput is only for simple pagination with { input { pagination } } variable",
        { currentInput, resolvedVariables: resolvedVariables.value }
      );
      return;
    } else {
      const input = currentInput; // rename variable

      // check if current data has page info which is important for the next page offset
      if (
        !data?.value ||
        !data?.value?.["pageInfo"] ||
        !data?.value?.["pageInfo"]?.nextOffset
      ) {
        console.warn(
          "Not sure how handle this, current data doesn't have pageInfo or pageInfo.nextOffset property",
          data?.value
        );
        return;
      }

      // create new input pagination variable
      input["pagination"] = {
        offset: data?.value?.["pageInfo"].nextOffset,
        pageSize: currentInput.pagination.pageSize,
      };

      fetchMoreLoading.value = true;
      await fetchMore({
        variables: {
          input,
        } as unknown as TGqlVariables, // assert type for the variables
        updateQuery: (previousResult, { fetchMoreResult }) =>
          fetchMoreQueryUpdater(rootKey, previousResult, fetchMoreResult),
      });
      fetchMoreLoading.value = false;
    }
  };

  return {
    loading,
    data,
    networkStatus,
    error,
    refetch,
    fetchMore,
    fetchMoreSimplePaginationInput,
    fetchMoreLoading,
    onResult,
    onError,
  };
};
