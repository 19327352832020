import gql from "graphql-tag";

export const poolNewsGql = gql`
  query PoolNews($input: PoolNewsInput!) {
    poolNews(input: $input) {
      ... on PoolNewsResults {
        results {
          id
          body
          created
          createdBy {
            id
            username
            name
            avatarUrl
          }
          pool {
            id
            address
            status
          }
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
