import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_tabs, {
    activeKey: _ctx.activeTabKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabKey) = $event)),
    class: "custom-tabs",
    size: "small"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_a_tab_pane, {
          key: tab.name
        }, {
          tab: _withCtx(() => [
            (tab.icon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(tab.icon)))
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(tab.title), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(tab.component), _normalizeProps(_guardReactiveProps(_ctx.childProps)), null, 16))
            ], 1024))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["activeKey"]))
}