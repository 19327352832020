import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "layout-card" }, {
    title: _withCtx(() => [
      (_ctx.title || _ctx.$slots['title-right'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_a_typography_title, {
                  key: 0,
                  level: 3,
                  class: "whitespace-normal m-0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "title-right")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "title")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _renderSlot(_ctx.$slots, "footer")
    ]),
    _: 3
  }))
}