import gql from "graphql-tag";

export const updatePoolGql = gql`
  mutation UpdatePool($input: UpdatePoolInput!) {
    updatePool(input: $input) {
      ... on PoolItem {
        id
        voteId
        hash
        status
        created
        modified
        poolDetail {
          name
          ticker
          url
          logo
          longDescription
          shortDescription
        }
        isVoted
        isOwned
        pledgeAmount
        roaPercentage
        lifetimePercentage
        luckPercentage
        epoch {
          id
          created
          number
        }
        poolEpochDetail {
          blocksCount
          delegatorsCount
          activeStakeAmount
          poolRewardsAmount
          delegatorRewardsAmount
          poolHash
          epochNo
        }
        poolVoteDetail {
          voteCount
          voteStrike
          voteCountDifference
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
