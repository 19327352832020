
import { usePoolNews } from "@/shared/composables/usePoolNews";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";
import { formatDateTimePretty } from "@/shared/utils/date";

export default defineComponent({
  props: {
    poolHash: String,
    readonly: Boolean,
  },
  setup(props) {
    const { t } = useI18n();
    const {
      poolNewsList,
      poolNewsListLoading,
      deletePoolNews,
      deletePoolNewsLoading,
    } = usePoolNews(props.poolHash);

    const currentPoolNewsIdDelete = ref<number>();

    const handleDeletePoolNews = async (poolNewsId) => {
      try {
        currentPoolNewsIdDelete.value = poolNewsId;
        await deletePoolNews({ poolNewsId });
      } catch (error) {
        console.log("handleDeletePoolNews", error);
      } finally {
        currentPoolNewsIdDelete.value = undefined;
      }
    };

    return {
      t,
      ConfirmModalTypes,
      poolNewsList,
      poolNewsListLoading,
      handleDeletePoolNews,
      deletePoolNewsLoading,
      currentPoolNewsIdDelete,
    };
  },
  methods: { formatDateTimePretty },
  components: { AppIcon, ConfirmModal },
});
