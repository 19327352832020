
import { defineComponent, PropType } from "vue";
import { useVModel } from "vue-composable";

/**
 * The icon and component should be a type component
 * is is rendered with <component :is="component_name" />
 */
export type CustomTabItem = {
  name: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
};

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<CustomTabItem[]>,
    },
    activeKey: {
      type: String,
    },
    childProps: {
      type: Object,
    },
  },
  setup(props) {
    const activeTabKey = useVModel(props, "activeKey");

    return { activeTabKey };
  },
});
