import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createPoolNewsGql } from "@/api/pool/createPoolNews";
import { deletePoolNewsGql } from "@/api/pool/deletePoolNews";
import { poolNewsGql } from "@/api/pool/poolNews";
import {
  CreatePoolNews,
  CreatePoolNewsVariables,
  CreatePoolNews_createPoolNews_PoolNews,
} from "@/api/pool/__generated__/CreatePoolNews";
import {
  DeletePoolNews,
  DeletePoolNewsVariables,
  DeletePoolNews_deletePoolNews_GenericSuccess,
} from "@/api/pool/__generated__/DeletePoolNews";
import {
  PoolNewsVariables,
  PoolNews_poolNews_PoolNewsResults,
} from "@/api/pool/__generated__/PoolNews";
import { useQuery } from "@vue/apollo-composable";
import isEmpty from "lodash/isEmpty";
import { computed } from "vue";
import { CreatePoolNewsInput } from "../../../__generated__/globalTypes";
import { parseGqlResponse } from "../utils/graphql/responseParser";

export const usePoolNews = (poolHashToFetchNews = "") => {
  const poolNewsListQueryVariable = {
    input: { poolHash: poolHashToFetchNews },
  };
  const poolNewsListRefetchQuery = [
    {
      query: poolNewsGql,
      variables: poolNewsListQueryVariable,
    },
  ];

  const { loading: poolNewsListLoading, result: poolNewsResults } = useQuery<
    PoolNews_poolNews_PoolNewsResults,
    PoolNewsVariables
  >(
    poolNewsGql,
    poolNewsListQueryVariable,
    () => ({
      enabled: !!poolHashToFetchNews, // zero the default value will not fetch the news list
    })
    // do not query if required input is empty
  );

  const poolNewsList = computed(() => {
    const parsedResponse = parseGqlResponse<PoolNews_poolNews_PoolNewsResults>(
      "PoolNewsResults",
      poolNewsResults.value
    );

    console.log("poolNews: parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !parsedResponse) {
      /**
       * Get error message but do not show any error via toast with HideIfErrorCode.ALL_ERRORS
       */
      console.error("poolNewsList error");
    }

    // return application details
    return parsedResponse.data?.results ?? [];
  });

  const { mutate: createPoolNewsMutate, loading: createPoolNewsLoading } =
    useCustomMutation<CreatePoolNews, CreatePoolNewsVariables>(
      createPoolNewsGql,
      {
        awaitRefetchQueries: true,
        refetchQueries: poolNewsListRefetchQuery,
      }
    );
  const createPoolNews = async (input: CreatePoolNewsInput) => {
    console.log("createPoolNews", input);
    const createPoolNewsResponse = await createPoolNewsMutate({ input });
    const parsedResponse =
      parseGqlResponse<CreatePoolNews_createPoolNews_PoolNews>(
        "InitiateAuthenticationViaExternalWalletResponseDetail",
        createPoolNewsResponse
      );

    console.log("createPoolNews:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !createPoolNewsResponse) {
      throw new Error("Failed to create new pool news");
    }

    return parsedResponse.data;
  };

  const { mutate: deletePoolNewsMutate, loading: deletePoolNewsLoading } =
    useCustomMutation<DeletePoolNews, DeletePoolNewsVariables>(
      deletePoolNewsGql,
      {
        awaitRefetchQueries: true,
        refetchQueries: poolNewsListRefetchQuery,
      }
    );
  const deletePoolNews = async ({ poolNewsId }) => {
    const deletePoolNewsResponse = await deletePoolNewsMutate({
      input: { poolNewsId },
    });
    const parsedResponse =
      parseGqlResponse<DeletePoolNews_deletePoolNews_GenericSuccess>(
        "GenericSuccess",
        deletePoolNewsResponse
      );

    console.log("deletePoolNews:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !deletePoolNewsResponse) {
      throw new Error("Failed to delete pool news");
    }

    return parsedResponse.data;
  };

  return {
    poolNewsList,
    poolNewsListLoading,
    createPoolNews,
    createPoolNewsLoading,
    deletePoolNews,
    deletePoolNewsLoading,
  };
};
