import gql from "graphql-tag";

export const createPoolNewsGql = gql`
  mutation CreatePoolNews($input: CreatePoolNewsInput!) {
    createPoolNews(input: $input) {
      ... on PoolNews {
        id
        body
        createdBy {
          id
          username
          name
          avatarUrl
        }
        pool {
          id
          address
          status
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
