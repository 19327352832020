import get from "lodash/get";
import isArray from "lodash/isArray";

/**
 * Experimental fetch more query updater
 *
 * @param rootKey - the root key found on TData
 * @param previousResult - fetch more function from updateQuery: (previousResult, { fetchMoreResult })
 * @param fetchMoreResult - fetch more function from updateQuery: (previousResult, { fetchMoreResult })
 * @param combinedKeys - optional keys to combine values together - for object incoming query results is prioritize while on array data it is concatenated
 * @returns TData
 */
export const fetchMoreQueryUpdater = <TData>(
  rootKey = "",
  previousResult,
  fetchMoreResult,
  combinedKeys: string[] = ["results", "pageInfo"]
): TData => {
  // There's no new result
  if (!fetchMoreResult || (rootKey && !fetchMoreResult[rootKey])) {
    console.log("It seems there's no new data", {
      previousResult,
      fetchMoreResult,
    });
    return previousResult;
  }

  /**
   * combine previousResult and fetchMoreResult
   * this will combine other values not set on combinedKeys
   */
  const updatedResult = {
    ...get(previousResult, [rootKey], {}),
    ...get(fetchMoreResult, [rootKey], {}),
  };

  /**
   * Combine the keys and values
   * Usually this is pageInfo and results
   */
  combinedKeys.forEach((fieldKey) => {
    const prevValue = get(previousResult, [rootKey, fieldKey]);
    const newValue = get(fetchMoreResult, [rootKey, fieldKey]);

    /**
     * If the value is an array - concat the values
     * else merge the value with the new one as prioritize value
     */
    if (isArray(newValue) && isArray(prevValue)) {
      updatedResult[fieldKey] = [...prevValue, ...newValue];
    } else {
      updatedResult[fieldKey] = { ...prevValue, ...newValue };
    }
  });

  /**
   * return with root key is exists - root keys are common because of union type on error and success results
   */
  return rootKey ? { [rootKey]: updatedResult } : updatedResult;
};
